import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilService {

  private showFormObs$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    getShowFormObs(): Observable<boolean> {
      return this.showFormObs$.asObservable();
    }

    setShowFormObs(showForm: boolean) {
      this.showFormObs$.next(showForm);
    }
}
