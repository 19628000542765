import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: "about-eb",
    templateUrl: "./about-eb.component.html",
    styleUrls: ["./about-eb.component.scss"]
})

export class AboutEBComponent implements OnInit{
    public isCollapsed = true;
    public isCollapsed2 = true;
    public isCollapsed3 = true;
    public isCollapsed4 = true;
    public isCollapsed5 = true;

    accordionData: Array<{title: string; body: string;}> = [
      {
        title: 'How is my electricity bill calculated?',
        body: 'There are few components to your electricity bill which include your contracted rated per kWh with your retail energy provider, base rate of the retail energy provider, TDU rates, taxes, fees and sometimes hidden charges and credits based on your usage.'
      },
      {
        title: 'How does MyEnergyBuddy estimate my charges?',
        body: 'MyEnergyBuddy checks for all available plans in your area (100\'s of plans after avoiding plans with marketing gimmicks and complications) and calculates your estimated bill using your usage. You are then shown customized best plans to select.'
      },
      {
        title: 'Why is my current usage important?',
        body: 'Each of the plans charge differently based on usage and it is important to estimate the total cost based on actual usages. Some plans might be cheaper when usage is higher and others for lower usage. MyEnergyBuddy simply pick the best plan based your usage.'
      },
      {
        title: 'I am moving to a new home, can MyEnergyBuddy help?',
        body: 'Yes, we have provided you the average usage of Texans by home type. You can use it to estimate your bill.'
      },
      {
        title: 'How is MyEnergyBuddy unbiased?',
        body: 'We look at all available plans and show you the best. We remove all plans that ask for your special commitments. This is an absolutely free service and we work independently. There is no partnership with any providers and do not give preference to anyone (Only preference is to all of Texans and a commitment to save hundreds of dollars).'
      },
      {
        title: 'Where can I find my Meter Number?',
        body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus veritatis officia in fugiat excepturi, itaque voluptates sint necessitatibus architecto facilis, dignissimos deserunt quisquam est aliquid eius placeat magnam maiores quia?'
      },
      {
        title: 'Why is it not possible to look the average rates published by the electricity providers and pick the lowest rate plan?',
        body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus veritatis officia in fugiat excepturi, itaque voluptates sint necessitatibus architecto facilis, dignissimos deserunt quisquam est aliquid eius placeat magnam maiores quia?'
      },
      {
        title: 'How does MyEnergyBuddy estimate my charges?',
        body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus veritatis officia in fugiat excepturi, itaque voluptates sint necessitatibus architecto facilis, dignissimos deserunt quisquam est aliquid eius placeat magnam maiores quia?'
      },
      {
        title: 'How much can I save by switching?',
        body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus veritatis officia in fugiat excepturi, itaque voluptates sint necessitatibus architecto facilis, dignissimos deserunt quisquam est aliquid eius placeat magnam maiores quia?'
      },
      {
        title: 'How does MyEnergyBuddy show ratings against each of the plans?',
        body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus veritatis officia in fugiat excepturi, itaque voluptates sint necessitatibus architecto facilis, dignissimos deserunt quisquam est aliquid eius placeat magnam maiores quia?'
      },
      {
        title: 'I cannot find my Meter Number, can MyEnergyBuddy still help?',
        body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus veritatis officia in fugiat excepturi, itaque voluptates sint necessitatibus architecto facilis, dignissimos deserunt quisquam est aliquid eius placeat magnam maiores quia?'
      },
      {
        title: 'How does MyEnergyBuddy get my actual usage?',
        body: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus veritatis officia in fugiat excepturi, itaque voluptates sint necessitatibus architecto facilis, dignissimos deserunt quisquam est aliquid eius placeat magnam maiores quia?'
      },
    ]

    @ViewChild('faq')
    faqRef: ElementRef;

    constructor(private router:Router) {
        console.log("Find Plan constructor")
    }

    ngOnInit() {

        let uriPath = this.router.url;
        console.log("Path=" + uriPath);

        if(uriPath === '/faq') {
            setTimeout (() => {
                this.faqRef.nativeElement.scrollIntoView();
             }, 100);
        }

    }
}
