<div class="eb-bg-clor eb-gbl-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12" style="text-align: center; margin-top: 20px;margin-bottom: 20px;">
                <span class="eb-h2">Your usage decides your energy cost</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                &nbsp;
            </div>
            <div class="col-md-4">
                <div class="eb-line-space">
                    <div style="display: flex;">
                        <span>Enter your usage* </span>
                        <div (mouseenter)="count = count + 1" (mouseleave)="count = count - 1">
                            <img style="margin-left: 5px;height: 17px;" src="/assets/images/question.png">
                            <span class="eb-tooltips" *ngIf="count === 2">
                                <b>If you look at the latest electricity invoice you can find the latest month usage in
                                    kWh</b>
                                <br>Or
                                <br><b>You can pick based on the below Texas average usage:</b>
                                <br>One-bed apartment ~ 600 to 750 kWh
                                <br>Two-bed apartment ~ 800 to 900 kWh
                                <br>Small home ~ 1100 to 1325 kWh
                                <br>Large home ~ 2000 kWh
                            </span>
                        </div>
                    </div>
                    <!--
                    <input [(ngModel)]="form.meterId" [ngClass]="{'eb-alert' : error && form.meterId.length < 1}"
                        type="text" class="form-control eb-text-fld" aria-label="Text input with dropdown button"
                        placeholder="Enter here (in KwH)" (keyup)="keyPress($event)">
                    -->
                    <mat-slider style="width: 100%;color: rgb(64, 196, 82);" color="primary" thumbLabel min="100" max="9999" step="1" value="400" [(ngModel)]="form.meterId" [displayWith]="formatLabel" ></mat-slider>
                </div>
            </div>
            <div class="col-md-3">
                <div class="eb-line-space">
                    Enter zip code*
                    <input [(ngModel)]="form.zipCode" [ngClass]="{'eb-alert' : error && form.zipCode.length < 1}"
                        type="text" class="form-control eb-text-fld" aria-label="Text input with dropdown button"
                        placeholder="Enter here" (keyup)="keyPress($event)">
                </div>
            </div>
            <div class="col-md-3">
                <div class="eb-line-space">
                    &nbsp;
                </div>
                <button class="eb-up-btn eb-glb-btn eb-line-space" (click)="goToResultWOSU($event)">Find my best plans</button>
            </div>
            <div class="col-md-1">
                &nbsp;
            </div>
        </div>

        <div class="row">
            <div class="col-md-1">
                &nbsp;
            </div>
            <div class="col-md-11">
                <div class="form-check form-check-inline eb-line-space">
                    <input [(ngModel)]="form.isAgree" [ngClass]="{'eb-alert' : error && form.isAgree !== true}"
                        class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                        (keyup)="keyPress($event)">
                        <!--
                    <label style="font-size: 13px;" class="form-check-label" for="inlineCheckbox1">Agree to <u
                            (click)="showHelp()" style="color: #17af10; cursor: pointer;">terms & conditions</u></label>
                            -->
                    <label style="font-size: 13px;" class="form-check-label" for="inlineCheckbox1">
                        Agree to terms & conditions and I give my consent to pull my energy usage
                    </label>
                </div>
            </div>

        </div>
    </div>
    <br>
    <div style="width: 100%; position: relative;">
        <div style="position: relative; top: 25px; padding-left: 20px; padding-right: 10px; width: 50%; height: 50px; color: #3e455c; background-color: white; margin-left: auto;margin-right: auto;box-shadow: 2px 2px 4px 1px #cccccc;; border-radius: 30px;">
            <div style="display: flex;flex-wrap: nowrap;">
                <div style="width: 33%; padding-top: 15px;">
                    <span [class] = "{'eb-active':idSignUpActive, 'eb-not-active':!idSignUpActive}" style="padding-left: 8px; padding-right: 8px; border-radius: 50%;">1</span>
                    <span>&nbsp;Get Started</span>
                </div>
                <div style="width: 33%; padding-top: 15px; text-align: center;">
                    <span [class] = "{'eb-active':isConfirmActive, 'eb-not-active':!isConfirmActive}" style="padding-left: 7px; padding-right: 7px; border-radius: 50%;">2</span>
                    <span>&nbsp;Confirm Email</span>
                </div>
                <div style="width: 33%; padding-top: 15px; padding-left: 20px; text-align: right;">
                    <span [class] = "{'eb-active':isCompleteProfileActive, 'eb-not-active':!isCompleteProfileActive}" style="padding-left: 7px; padding-right: 7px; border-radius: 50%;">3</span>
                    <span>&nbsp;Complete Profile</span>
                </div>
            </div>
        </div>
    </div>
</div>