import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUtilService } from '../../../service/common-util.service';

@Component({
  selector: "why-energyBuddyr",
  templateUrl: "./whyEnergyBuddy.component.html",
  styleUrls: ["./whyEnergyBuddy.component.scss"]
})

export class WhyEnergyBuddyComponent implements OnInit {

  images: string[] = [
    '/assets/images/swiper/4change.png',
    '/assets/images/swiper/ambit.png',
    '/assets/images/swiper/bounce.png',
    '/assets/images/swiper/chariot.png',
    '/assets/images/swiper/cirro.png',
    '/assets/images/swiper/cpl.png',
    '/assets/images/swiper/directenergy.png',
    '/assets/images/swiper/discount.png',
    '/assets/images/swiper/express.png',
    '/assets/images/swiper/firstchoice.png',
    '/assets/images/swiper/greenmountain.png',
    '/assets/images/swiper/mp2.png',
    '/assets/images/swiper/nrg.png',
    '/assets/images/swiper/providers-tomorrow.png',
    '/assets/images/swiper/reliant.png',
    '/assets/images/swiper/rythym.png',
    '/assets/images/swiper/spark.png',
    '/assets/images/swiper/stream.png',
    '/assets/images/swiper/veteran.png',
    '/assets/images/swiper/WTU.png',
    '/assets/images/swiper/xoom.png',
  ]

  @ViewChild('feature')
  featureRef: ElementRef;

  constructor(private router: Router, private commonUtilService: CommonUtilService) {
    console.log("whyEnergyBuddy Component constructor")
  }

  ngOnInit() {

    let uriPath = this.router.url;
    console.log("Path=" + uriPath);

    if (uriPath === '/feature') {
      setTimeout(() => {
        this.featureRef.nativeElement.scrollIntoView();
      }, 100);
    }

  }

  onGetRealSavingsClick() {
    this.commonUtilService.setShowFormObs(true);
  }
}
