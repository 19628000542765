<div class="w-full">
  <div class="container py-5 py-md-7">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-4 pe-0 pe-md-3">
        <div
          class="d-block p-5 p-lg-10 w-100 h-100 border text-center bg-primary-light border-radius-md nav-card"
        >
          <h4 class="text-navy">Hear From Other Texans</h4>
          <p class="subtext">Don’t take our word for it — listen to our satisfied clients!</p>

          <div class="swiper__navigation">
            <div
              class="swiper__navigation_prev swiper-button-disabled"
              tabindex="-1"
              role="button"
              aria-label="Previous slide"
              aria-controls="swiper-wrapper-91fcdd10a79fdcb4e"
              aria-disabled="true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 11.995 12.01"
              >
                <g>
                  <path
                    id="Vector"
                    d="M11.714-5.285l-5,5a1,1,0,0,1-.33.21,1,1,0,0,1-.76,0,1,1,0,0,1-.33-.21l-5-5a1,1,0,0,1-.218-.326A1,1,0,0,1,0-6a1,1,0,0,1,.294-.71A1,1,0,0,1,1-7a1,1,0,0,1,.71.294L5-3.405V-11A1,1,0,0,1,5.3-11.7,1,1,0,0,1,6-12a1,1,0,0,1,.707.293A1,1,0,0,1,7-11v7.59l3.29-3.3a1,1,0,0,1,.325-.219A1,1,0,0,1,11-7a1,1,0,0,1,.385.077,1,1,0,0,1,.325.219,1,1,0,0,1,.219.325A1,1,0,0,1,12.01-6a1,1,0,0,1-.077.385A1,1,0,0,1,11.714-5.285Z"
                    transform="translate(0 11.995)"
                    fill="#fff"
                  ></path>
                </g>
              </svg>
            </div>
            <div
              class="swiper__navigation_next"
              tabindex="0"
              role="button"
              aria-label="Next slide"
              aria-controls="swiper-wrapper-91fcdd10a79fdcb4e"
              aria-disabled="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12.01 11.995"
              >
                <path
                  id="Vector"
                  d="M11.714,5.285l-5-5a1.034,1.034,0,0,0-1.42,0l-5,5a1,1,0,0,0,1.42,1.42L5,3.405V11a1,1,0,1,0,2,0V3.405l3.29,3.3a1,1,0,1,0,1.42-1.42Z"
                  fill="#fff"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-8">
        <div
          class="swiper-container testimonial-swiper swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-free-mode"
          [swiper]="{}"
        >
          <div
            class="swiper-wrapper"
            id="swiper-wrapper-91fcdd10a79fdcb4e"
            aria-live="polite"
            style="
              transform: translate3d(0px, 0px, 0px);
              transition-duration: 0ms;
            "
          >
            <div
              class="swiper-slide mr-5 pb-0 pb-md-5 pt-5 swiper-slide-active"
              role="group"
              aria-label="1 / 6"
              style="width: 343px; margin-right: 30px"
            >
              <div class="border-radius-md bg-gray-100 p-3">
                <img
                  class="d-block img-70 ms-auto mt-n5 position-relative"
                  style="margin-top: -60px"
                  src="http://energybuddy.versacreativedev.com/wp-content/uploads/PNGs_Homepage_Testimonial_Placeholder.png "
                  alt="John Doe"
                />
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24.595"
                    viewBox="0 0 30 24.595"
                  >
                    <g
                      id="Group_2"
                      data-name="Group 2"
                      transform="translate(-24 -64)"
                    >
                      <g id="Group_1" data-name="Group 1">
                        <path
                          id="_"
                          data-name="“"
                          d="M12.613,12.072V24.595H0V16.306A21.952,21.952,0,0,1,1.622,7.748,24.674,24.674,0,0,1,7.117,0L11.8,3.6A35.618,35.618,0,0,0,8.378,8.018a11.974,11.974,0,0,0-1.532,4.054Zm17.387,0V24.595H17.387V16.306a21.952,21.952,0,0,1,1.622-8.559A24.674,24.674,0,0,1,24.5,0l4.685,3.6a35.618,35.618,0,0,0-3.423,4.414,11.974,11.974,0,0,0-1.532,4.054Z"
                          transform="translate(24 64)"
                          fill="#010967"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </p>
                <div class="wysiwyg mb-4">
                  <p class="card-text">
                    Eget et lobortis ornare nulla quis feugiat est elit,
                    condimentum. Vestibulum vitae elit arcu justo, a, pharetra
                    magna vivamus ac. Sagittis nisl, tortor, nulla a. Nibh
                    tristique pulvinar integer sagittis, diam odio. Vel orci,
                    eget fermentum iaculis turpis. Nascetur dignissim nibh fames
                    ac placerat id vel egestas a.
                  </p>
                </div>
                <h6 class="text-navy font-size-lg">John Doe</h6>
              </div>
            </div>
            <div
              class="swiper-slide mr-5 pb-0 pb-md-5 pt-5 swiper-slide-next"
              role="group"
              aria-label="2 / 6"
              style="width: 343px; margin-right: 30px"
            >
              <div class="border-radius-md bg-gray-100 p-3">
                <img
                  class="d-block img-70 ms-auto mt-n5 position-relative"
                  style="margin-top: -60px"
                  src="http://energybuddy.versacreativedev.com/wp-content/uploads/PNGs_Homepage_Testimonial_Placeholder2.png "
                  alt="John Doe"
                />
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24.595"
                    viewBox="0 0 30 24.595"
                  >
                    <g
                      id="Group_2"
                      data-name="Group 2"
                      transform="translate(-24 -64)"
                    >
                      <g id="Group_1" data-name="Group 1">
                        <path
                          id="_"
                          data-name="“"
                          d="M12.613,12.072V24.595H0V16.306A21.952,21.952,0,0,1,1.622,7.748,24.674,24.674,0,0,1,7.117,0L11.8,3.6A35.618,35.618,0,0,0,8.378,8.018a11.974,11.974,0,0,0-1.532,4.054Zm17.387,0V24.595H17.387V16.306a21.952,21.952,0,0,1,1.622-8.559A24.674,24.674,0,0,1,24.5,0l4.685,3.6a35.618,35.618,0,0,0-3.423,4.414,11.974,11.974,0,0,0-1.532,4.054Z"
                          transform="translate(24 64)"
                          fill="#010967"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </p>
                <div class="wysiwyg mb-4">
                  <p class="card-text">
                    Eget et lobortis ornare nulla quis feugiat est elit,
                    condimentum. Vestibulum vitae elit arcu justo, a, pharetra
                    magna vivamus ac. Sagittis nisl, tortor, nulla a. Nibh
                    tristique pulvinar integer sagittis, diam odio. Vel orci,
                    eget fermentum iaculis turpis. Nascetur dignissim nibh fames
                    ac placerat id vel egestas a.
                  </p>
                </div>
                <h6 class="text-navy font-size-lg">John Doe</h6>
              </div>
            </div>
            <div
              class="swiper-slide mr-5 pb-0 pb-md-5 pt-5"
              role="group"
              aria-label="3 / 6"
              style="width: 343px; margin-right: 30px"
            >
              <div class="border-radius-md bg-gray-100 p-3">
                <img
                  class="d-block img-70 ms-auto mt-n5 position-relative"
                  style="margin-top: -60px"
                  src="http://energybuddy.versacreativedev.com/wp-content/uploads/PNGs_Homepage_Testimonial_Placeholder.png "
                  alt="John Doe"
                />
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24.595"
                    viewBox="0 0 30 24.595"
                  >
                    <g
                      id="Group_2"
                      data-name="Group 2"
                      transform="translate(-24 -64)"
                    >
                      <g id="Group_1" data-name="Group 1">
                        <path
                          id="_"
                          data-name="“"
                          d="M12.613,12.072V24.595H0V16.306A21.952,21.952,0,0,1,1.622,7.748,24.674,24.674,0,0,1,7.117,0L11.8,3.6A35.618,35.618,0,0,0,8.378,8.018a11.974,11.974,0,0,0-1.532,4.054Zm17.387,0V24.595H17.387V16.306a21.952,21.952,0,0,1,1.622-8.559A24.674,24.674,0,0,1,24.5,0l4.685,3.6a35.618,35.618,0,0,0-3.423,4.414,11.974,11.974,0,0,0-1.532,4.054Z"
                          transform="translate(24 64)"
                          fill="#010967"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </p>
                <div class="wysiwyg mb-4">
                  <p class="card-text">
                    Eget et lobortis ornare nulla quis feugiat est elit,
                    condimentum. Vestibulum vitae elit arcu justo, a, pharetra
                    magna vivamus ac. Sagittis nisl, tortor, nulla a. Nibh
                    tristique pulvinar integer sagittis, diam odio. Vel orci,
                    eget fermentum iaculis turpis. Nascetur dignissim nibh fames
                    ac placerat id vel egestas a.
                  </p>
                </div>
                <h6 class="text-navy font-size-lg">John Doe</h6>
              </div>
            </div>
            <div
              class="swiper-slide mr-5 pb-0 pb-md-5 pt-5"
              role="group"
              aria-label="4 / 6"
              style="width: 343px; margin-right: 30px"
            >
              <div class="border-radius-md bg-gray-100 p-3">
                <img
                  class="d-block img-70 ms-auto mt-n5 position-relative"
                  style="margin-top: -60px"
                  src="http://energybuddy.versacreativedev.com/wp-content/uploads/PNGs_Homepage_Testimonial_Placeholder2.png "
                  alt="John Doe"
                />
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24.595"
                    viewBox="0 0 30 24.595"
                  >
                    <g
                      id="Group_2"
                      data-name="Group 2"
                      transform="translate(-24 -64)"
                    >
                      <g id="Group_1" data-name="Group 1">
                        <path
                          id="_"
                          data-name="“"
                          d="M12.613,12.072V24.595H0V16.306A21.952,21.952,0,0,1,1.622,7.748,24.674,24.674,0,0,1,7.117,0L11.8,3.6A35.618,35.618,0,0,0,8.378,8.018a11.974,11.974,0,0,0-1.532,4.054Zm17.387,0V24.595H17.387V16.306a21.952,21.952,0,0,1,1.622-8.559A24.674,24.674,0,0,1,24.5,0l4.685,3.6a35.618,35.618,0,0,0-3.423,4.414,11.974,11.974,0,0,0-1.532,4.054Z"
                          transform="translate(24 64)"
                          fill="#010967"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </p>
                <div class="wysiwyg mb-4">
                  <p class="card-text">
                    Eget et lobortis ornare nulla quis feugiat est elit,
                    condimentum. Vestibulum vitae elit arcu justo, a, pharetra
                    magna vivamus ac. Sagittis nisl, tortor, nulla a. Nibh
                    tristique pulvinar integer sagittis, diam odio. Vel orci,
                    eget fermentum iaculis turpis. Nascetur dignissim nibh fames
                    ac placerat id vel egestas a.
                  </p>
                </div>
                <h6 class="text-navy font-size-lg">John Doe</h6>
              </div>
            </div>
            <div
              class="swiper-slide mr-5 pb-0 pb-md-5 pt-5"
              role="group"
              aria-label="5 / 6"
              style="width: 343px; margin-right: 30px"
            >
              <div class="border-radius-md bg-gray-100 p-3">
                <img
                  class="d-block img-70 ms-auto mt-n5 position-relative"
                  style="margin-top: -60px"
                  src="http://energybuddy.versacreativedev.com/wp-content/uploads/PNGs_Homepage_Testimonial_Placeholder.png "
                  alt="John Doe"
                />
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24.595"
                    viewBox="0 0 30 24.595"
                  >
                    <g
                      id="Group_2"
                      data-name="Group 2"
                      transform="translate(-24 -64)"
                    >
                      <g id="Group_1" data-name="Group 1">
                        <path
                          id="_"
                          data-name="“"
                          d="M12.613,12.072V24.595H0V16.306A21.952,21.952,0,0,1,1.622,7.748,24.674,24.674,0,0,1,7.117,0L11.8,3.6A35.618,35.618,0,0,0,8.378,8.018a11.974,11.974,0,0,0-1.532,4.054Zm17.387,0V24.595H17.387V16.306a21.952,21.952,0,0,1,1.622-8.559A24.674,24.674,0,0,1,24.5,0l4.685,3.6a35.618,35.618,0,0,0-3.423,4.414,11.974,11.974,0,0,0-1.532,4.054Z"
                          transform="translate(24 64)"
                          fill="#010967"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </p>
                <div class="wysiwyg mb-4">
                  <p class="card-text">
                    Eget et lobortis ornare nulla quis feugiat est elit,
                    condimentum. Vestibulum vitae elit arcu justo, a, pharetra
                    magna vivamus ac. Sagittis nisl, tortor, nulla a. Nibh
                    tristique pulvinar integer sagittis, diam odio. Vel orci,
                    eget fermentum iaculis turpis. Nascetur dignissim nibh fames
                    ac placerat id vel egestas a.
                  </p>
                </div>
                <h6 class="text-navy font-size-lg">John Doe</h6>
              </div>
            </div>
            <div
              class="swiper-slide mr-5 pb-0 pb-md-5 pt-5"
              role="group"
              aria-label="6 / 6"
              style="width: 343px; margin-right: 30px"
            >
              <div class="border-radius-md bg-gray-100 p-3">
                <img
                  class="d-block img-70 ms-auto mt-n5 position-relative"
                  style="margin-top: -60px"
                  src="http://energybuddy.versacreativedev.com/wp-content/uploads/PNGs_Homepage_Testimonial_Placeholder2.png "
                  alt="John Doe"
                />
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24.595"
                    viewBox="0 0 30 24.595"
                  >
                    <g
                      id="Group_2"
                      data-name="Group 2"
                      transform="translate(-24 -64)"
                    >
                      <g id="Group_1" data-name="Group 1">
                        <path
                          id="_"
                          data-name="“"
                          d="M12.613,12.072V24.595H0V16.306A21.952,21.952,0,0,1,1.622,7.748,24.674,24.674,0,0,1,7.117,0L11.8,3.6A35.618,35.618,0,0,0,8.378,8.018a11.974,11.974,0,0,0-1.532,4.054Zm17.387,0V24.595H17.387V16.306a21.952,21.952,0,0,1,1.622-8.559A24.674,24.674,0,0,1,24.5,0l4.685,3.6a35.618,35.618,0,0,0-3.423,4.414,11.974,11.974,0,0,0-1.532,4.054Z"
                          transform="translate(24 64)"
                          fill="#010967"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </p>
                <div class="wysiwyg mb-4">
                  <p class="card-text">
                    Eget et lobortis ornare nulla quis feugiat est elit,
                    condimentum. Vestibulum vitae elit arcu justo, a, pharetra
                    magna vivamus ac. Sagittis nisl, tortor, nulla a. Nibh
                    tristique pulvinar integer sagittis, diam odio. Vel orci,
                    eget fermentum iaculis turpis. Nascetur dignissim nibh fames
                    ac placerat id vel egestas a.
                  </p>
                </div>
                <h6 class="text-navy font-size-lg">John Doe</h6>
              </div>
            </div>
          </div>

          <span
            class="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          ></span>
        </div>
      </div>
    </div>
  </div>
</div>
