<div class="w-full">
  <div
    class="container w-full d-flex flex-column align-items-center justify-content-between py-6 py-md-12 mx-auto"
  >
    <div class="container py-5 py-md-7">
      <div class="row">
        <div class="h-wrapper col-md-12 text-center">
          <h3 class="text-navy">How It Works</h3>
          <h5 class="text-primary font-weight-bold">(and it’s FREE)</h5>
          <p class="subtext">See our step by step process in saving hundreds of dollars.</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 my-2 my-md-0">
          <div class="d-block px-0 px-5 text-center">
            <img
              class="d-block img-250 m-auto"
              src="/assets/images/IMG_Homepage_How-1@2x.jpg"
              alt="Sign Up with MyEnergyBuddy "
            />
            <span class="badge badge-circle font-size-lg mb-2 m-auto"> 1 </span>
            <p class="heading text-navy line-height-160 font-weight-bolder mb-1">
              Sign Up with MyEnergyBuddy
            </p>
            <p class="subtext line-height-160 mb-0">
              Provide your home address and meter details.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 my-2 my-md-0">
          <div class="d-block px-0 px-5 text-center">
            <img
              class="d-block img-250 m-auto"
              src="/assets/images/IMG_Homepage_How-2@2x.jpg"
              alt="View Your Customized Dashboard "
            />
            <span class="badge badge-circle font-size-lg mb-2 m-auto"> 2 </span>
            <p class="heading text-navy line-height-160 font-weight-bolder mb-1">
              View Your Customized Dashboard
            </p>
            <p class="subtext line-height-160 mb-0">
              See how much you save with each of the plans.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 my-2 my-md-0">
          <div class="d-block px-0 px-5 text-center">
            <img
              class="d-block img-250 m-auto"
              src="/assets/images/IMG_Homepage_How-3@2x.jpg"
              alt="Pick Your Right Plan "
            />
            <span class="badge badge-circle font-size-lg mb-2 m-auto"> 3 </span>
            <p class="heading text-navy line-height-160 font-weight-bolder mb-1">
              Pick Your Right Plan
            </p>
            <p class="subtext line-height-160 mb-0">
              Easy signup and choice by price, green energy &amp; other
              recommended options.
            </p>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 5rem;">
        <div class="col-md-12 text-center">
          <a
            class="btn btn-primary me-2 get-plan py-4 px-5 "
            href="#"
            title="Start Your Savings Now"
            target=""
            (click)="onStartSavingsCLick()"
          >
            Start Your Savings Now</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
