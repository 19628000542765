<div>
    <hr style="width: 96%;">
    <div class="hide-on-mobile" >
        <span style="float: left;margin-left: 20px;">© 2022 MyEnergyBuddy</span>
        <span style="float: right; margin-right: 25px;"><a (click)="showTnc()" style="cursor: pointer;">Terms &
                Conditions</a> | <a (click)="showPolicy()" style="cursor: pointer;">Privacy
                Policy</a></span>
    </div>
    <div class="hide-on-desktop" style="margin: 60px 0px 5px 0px;">
        <span style="font-size: 12px;">© 2022 MyEnergyBuddy</span>
        <br>
        <span style="font-size: 14px;"><a (click)="showTnc()" style="cursor: pointer;">Terms &
                Conditions</a> | <a (click)="showPolicy()" style="cursor: pointer;">Privacy
                Policy</a></span>
    </div>
</div>
