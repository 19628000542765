<div class="eb-bg-clor eb-gbl-padding" *ngIf="showPlan">
    <div class="container">
        <div class="row">
            <div class="col-md-6 hide-on-mobile" style="padding-bottom: 30px; padding-top: 30px; padding-left: 30px;">
                <img [src]=imageSrc width="70%"  alt="">
            </div>
            <div class="col-md-6" style="padding-top: 70px; padding-bottom: 50px;">
                <div>
                    <span class="eb-h1">Your usage decides your energy cost</span>
                </div>
                <div>
                    <span>Helping Texans reduce their electricity bills</span>
                </div>

                <div class="eb-line-space">
                    <div style="display: flex;">
                        <span >Enter your usage* </span>
                        <div (mouseenter) ="count = count + 1" (mouseleave) ="count = count - 1">
                            <img style="margin-left: 5px;height: 17px;" src="/assets/images/question.png">
                            <span class="eb-tooltips" *ngIf="count === 2">
                                <b>If you look at the latest electricity invoice you can find the latest month usage in kWh</b>
                                <br>Or
                                <br><b>You can pick based on the below Texas average usage:</b>
                                <br>One-bed apartment ~ 600 to 750 kWh
                                <br>Two-bed apartment ~ 800 to 900 kWh
                                <br>Small home ~ 1100 to 1325 kWh
                               <br>Large home ~ 2000 kWh
                            </span>
                        </div>
                    </div>
                    <!-- <img style="margin-left: 5px;height: 17px;" src="/assets/images/question.png" placement="button" ngbTooltip="If you look at the latest electricity invoice you can find the latest month usage in kWh
                                            Or
                                            You can pick based on the below Texas average usage:
                                            One-bed apartment ~ 600 to 750kWh
                                            Two-bed apartment ~ 800 to 900 kWh
                                            Small home ~ 1100 to 1325 kWh
                                            Large home ~ 2000 kWh." tooltipClass="eb-looltips-class"> -->
                    <input [(ngModel)]="form.meterId" [ngClass]="{'eb-alert' : error && form.meterId.length < 1}" type="text" class="form-control eb-text-fld" aria-label="Text input with dropdown button" placeholder="Less than 10000 kWh" (keyup)="keyPress($event)">
                </div>

                <div class="eb-line-space">
                    Enter zip code*
                    <input [(ngModel)]="form.zipCode" [ngClass]="{'eb-alert' : error && form.zipCode.length < 1}" type="text" class="form-control eb-text-fld" aria-label="Text input with dropdown button" placeholder="Valid Texas ZipCode" (keyup)="keyPress($event)">
                </div>

                <div class="form-check form-check-inline eb-line-space">
                    <input [(ngModel)]="form.isAgree" [ngClass]="{'eb-alert' : error && form.isAgree !== true}" class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" (keyup)="keyPress($event)">
                    <label style="font-size: 13px;" class="form-check-label" for="inlineCheckbox1">Agree to <u (click)="showHelp()" style="color: #17af10; cursor: pointer;">terms & conditions</u></label>
                </div>
                <br>
                <br>
                <button class="eb-up-btn eb-glb-btn" (click)="goToResultWOSU($event)">Find my best plans</button>
            </div>
        </div>
    </div>
</div>

<!-- <section class="bg-img bg-img-top" id="custom-block_60afa468e73d0" style="background-color:#eef3fa;">

  <div class="container py-6 md:py-12">
    <div class="flex flex-row items-center justify-between">

      <div class="flex flex-row order-2 jsutify-start lg:justify-center pt-5 lg:pt-lg0">

        <div class="flex flex-row">
          <div class="grid grid-cols-1">
            <div class="wysiwyg text-mobile-center">
              <h1 style="color: #373f41;">Helping Texans<br />
                reduce their<br />
                electricity bills.</h1>
              <h3 style="color: #373F41;">Save more</h3>
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="grid grid-cols-1 sm:text-center md:text-left">
            <a class="btn btn-primary me-2" href="#" title="Get Your Customized Plan" target="">
              Get Your Customized Plan</a>
          </div>

          <div class="patent">
            <img src="/assets/images/Homepage_Patent@2x.png" alt="patent" width="auto" height="55%"><span>MyEnergyBuddy
              has developed a patent pending algorithm to calculate the best savings from 1000s of the plans.</span>
          </div>
        </div>


      </div>

      <div class="col-lg-5 order-1 order-lg-2 text-center">

        <h5 class="pb-2 font-weight-normal">Top Three Recommended Plans on <u>12345</u></h5>

        <img class="d-block w-100" src="/assets/images/Homepage_Mascot@2x@2x.png" alt="">
      </div>

    </div>
  </div>

</section> -->
<div class="w-full"  style="background-color: #eef3fa;" *ngIf="!showPlan">
  <div class="container w-full d-flex flex-column flex-md-row align-items-center justify-content-between py-6 py-md-12 mx-auto">
    <div class="h-wrapper d-flex flex-column  justify-content-center order-2 order-md-1 col-lg-5">
      <h1 class="text-center text-md-left mb-5" style="color: #373f41;">Helping Texans <br> reduce their <br> electricity bills.</h1>
      <h3 class="text-center text-md-left mb-3" style="color: #373f41;">Save more</h3>
      <div class="text-center text-md-left">
        <button class="btn btn-primary py-4 px-5 get-plan" (click)="showPlan = true">Get Your Customized Plan</button>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-start mt-4 ">
        <div class="mr-3">
          <img src="/assets/images/Homepage_Patent@2x.png" alt="" width="55px">
        </div>
        <div class="p-0" >
          <span style="font-size: 1.6rem; color: #373f41;">MyEnergyBuddy has developed a patent pending algorithm to calculate the best savings from 1000s of the plans.</span>
        </div>
      </div>
    </div>
    <div class="h-wrapper d-flex flex-column order-1 order-md-2 col-lg-5 align-items-center justify-content-center ">
        <h5 class="pb-2 font-weight-normal" style="color: #373f41;">Top Three Recommended Plans on 77077</h5>
        <img class="w-100" src="/assets/images/logo.png" alt="" >
    </div>
  </div>
</div>
