import { Component, OnInit } from '@angular/core';
import { CommonUtilService } from '../../service/common-util.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  constructor(private commonUtilService: CommonUtilService) { }

  ngOnInit(): void {
  }

  onStartSavingsCLick() {
    this.commonUtilService.setShowFormObs(true);
  }

}
