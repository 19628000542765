import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hear-from-others',
  templateUrl: './hear-from-others.component.html',
  styleUrls: ['./hear-from-others.component.scss']
})
export class HearFromOthersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
