<div class="eb-bg-clor eb-gbl-padding" style="background-color: #010967;">
    <div class="container">
        <div class="row" >
            <div class="col" style="text-align: center; padding-top: 50px; padding-bottom: 60px;">
                <div #reviews>
                    <span class="eb-h1" style="color: white">Hear From a Real Customer!</span>
                </div>
                <div style="margin-bottom: 50px; color: white">
                <span style=" font-size: 1.6rem; ">See how Stacey saved $421 on her energy bill.</span>
                </div>
                <div style="width: 100%; display: block;">
                    <div style="width: 80%; position: relative;margin-left: auto; margin-right: auto;">
                        <div *ngIf="showVideoImage" style="position: absolute;z-index: 150;">
                            <img style="width: 100%;" src="./assets/images/video_img.png"   alt="">
                            <br>
                            <img class="eb-play-btn-pos" src="./assets/images/play.png"  alt="" (click)="playVideo($event)">
                        </div>
                        <div style="position: relative; width:100%">
                            <video (click)="playVideo($event)" style="width: 100%;" [ngClass]="{'eb-hide-video' : showVideoImage}" #videoEl>
                                <source src="./assets/images/MyEnergyBuddy.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                  <a class="btn btn-primary py-4 px-5 get-plan" href="#" (click)="onSeeYourSavingsClick()">See Your Savings</a>
                </div>
            </div>
        </div>

    </div>
</div>
