<div>
    <div class="modal-header" style="background-image: linear-gradient(to right, #03145a, #00187b); color: white;">
        <h5 class="modal-title">Error</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ fromParent }}</p>
    </div>
    <div class="modal-footer" style="text-align: center;">
        <button type="button" class="eb-up-btn eb-glb-btn" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    </div>
</div>