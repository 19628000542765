<nav class="navbar navbar-expand-lg navbar-dark eb-bg-clor" style="position: relative;">
  <div *ngIf="showIt"
    style="z-index: 150; width: 210px; height: 100px; ;background-color: #ffffff40; border: 1px solid #494b52; border-radius: 12px; position: absolute; right: 0; margin-right: 106px; margin-top: 68px;">
    <div style="text-align: center;">
      <div style="margin-top: 20px; cursor: pointer;">
        <span (click)="showLogout('tst')"> Profile</span>
      </div>
      <div style="margin-top: 20px; cursor: pointer;" (click)="showLogout('tst')">
        LogOut
      </div>
    </div>
  </div>
  <a class="navbar-brand" routerLink="/home">
    <img src="./assets/images/logo_big.png" width="150" alt="">
  </a>
  <button (click)="tougleNavBar()" class="navbar-toggler" type="button" data-toggle="collapse"
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse eb-header" [ngClass]="{'show': showNaveBar}" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto row align-items-center">
      <!-- <li class="nav-item " *ngIf="!isLoggedIn()">
        <a routerLink="/feature" class="nav-link nav-link-item" routerLinkActive="active">Feature</a>
      </li> -->
      <!--
        <li class="nav-item" >
            <a routerLink="login/signin" class="nav-link nav-link-item" routerLinkActive="active">How it works</a>
        </li>
        -->
        <li class="nav-item" *ngIf="!isLoggedIn()">
          <a routerLink="reviews" class="nav-link nav-link-item" routerLinkActive="active">How it Works</a>
        </li>
        <li class="nav-item" *ngIf="!isLoggedIn()">
          <a routerLink="reviews" class="nav-link nav-link-item" routerLinkActive="active">Why Choose MyEnergyBuddy</a>
        </li>
        <li class="nav-item" *ngIf="!isLoggedIn()">
          <a routerLink="reviews" class="nav-link nav-link-item" routerLinkActive="active">About Us</a>
        </li>
      <li class="nav-item" *ngIf="!isLoggedIn()">
        <a routerLink="reviews" class="nav-link nav-link-item" routerLinkActive="active">Reviews</a>
      </li>
      <li class="nav-item" *ngIf="!isLoggedIn()">
        <a routerLink="/faq" class="nav-link nav-link-item" routerLinkActive="active">FAQ</a>
      </li>
      <li class="nav-item" *ngIf="!isLoggedIn()">
        <a routerLink="/faq" class="nav-link nav-link-item" routerLinkActive="active">Service Areas</a>
      </li>
      <li class="button special eb-sign-up-btn" *ngIf="!isLoggedIn()">
        <a class="nav-link nav-link-item login-btn  py-4 px-5" routerLinkActive="active" (click)="openModal('login')">Login</a>
      </li>

      <li class="button special" *ngIf="false">
        <a class="nav-link nav-link-item" routerLinkActive="active" (click)="showLogout('tst')"><b
            style="color: white; cursor: pointer;">&nbsp;&nbsp;&nbsp;&nbsp;test&nbsp;&nbsp;&nbsp;&nbsp;</b></a>
      </li>

      <li style="border: 1px solid white; border-radius: 30px; cursor: pointer;" class="button special" *ngIf="false">
        <a class="nav-link nav-link-item" routerLinkActive="active" (click)="logout()"><b style="color: white;">&nbsp;&nbsp;&nbsp;
            <span>
              <svg class="bi bi-person-circle" width="20px" height="20px" viewBox="0 0 16 16" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
              </svg>
            </span>
            &nbsp; My Account&nbsp;&nbsp;&nbsp;&nbsp;</b></a>
      </li>

      <li style="border: 1px solid white; border-radius: 30px; cursor: pointer;" class="button special"
        *ngIf="isLoggedIn()">

        <div ngbDropdown placement="bottom-right" ngDefaultControl class="d-inline-block">
          <button style="border: 1px solid #ced4da" class="btn eb-text-fld" id="dropdownBasic1" ngbDropdownToggle
            [(ngModel)]="profileVal" ngDefaultControl style="color: white;"> <span style="font-size: 14px;">

              <span>
                <svg class="bi bi-person-circle" width="20px" height="20px" viewBox="0 0 16 16" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
                  <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                </svg>
              </span>
              &nbsp;
              My Account
            </span>
          </button>
          <div ngbDropdownMenu ngDefaultControl aria-labelledby="dropdownBasic1">
            <button style="font-size: 14px;" ngbDropdownItem ngDefaultControl (click)="myProfile()">
              <span> Dashboard </span></button>
              <button style="font-size: 14px;" ngbDropdownItem ngDefaultControl (click)="editProfile()">
                <span> Edit Profile </span></button>
            <button style="font-size: 14px;" ngbDropdownItem ngDefaultControl (click)="changePassword()">
              <span> Change Password </span></button>
            <button style="font-size: 14px;" ngbDropdownItem ngDefaultControl (click)="logout()">
              <span> Logout </span></button>
          </div>
        </div>

      </li>
    </ul>
  </div>
</nav>
