<!--
<div>
    <input type="text" id="place" #searchEl [formControl]="searchControl" autocomplete="off" autocapitalize="off" spellcheck="off">
</div>
-->

<div>
    <div>
        <find-plan-shared></find-plan-shared>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="eb-line-space"
                    style="color: #3e455c; text-align: center; margin-top: 40px; font-size: 19px; font-weight: bold;">
                    <span class="eb-h2">Complete your profile</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-8 eb-line-space">
                <div>
                    Meter ID*
                    <input [(ngModel)]="meterId" [ngClass]="{'eb-alert' : error && meterId.length < 1}" type="text"
                        class="form-control eb-text-fld" aria-label="Text input with dropdown button"
                        placeholder="Meter ID" (keyup)="keyPress($event)">
                </div>
            </div>
            <div class="col-md-2">
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-4 eb-line-space">
                <div>
                    Address*
                    <input [(ngModel)]="address" [ngClass]="{'eb-alert' : error && address === '' }" type="text"
                        class="form-control eb-text-fld" aria-label="Text input with dropdown button"
                        placeholder="address" (keyup)="keyPress($event)" id="place" #searchEl
                        [formControl]="searchControl" autocomplete="off" autocapitalize="off" spellcheck="off">
                </div>
            </div>
            <div class="col-md-4 eb-line-space">
                <div>
                    Apartment Number
                    <input [(ngModel)]="apartmentNumber" type="text" class="form-control eb-text-fld"
                        aria-label="Text input with dropdown button" placeholder="Apartment Number"
                        (keyup)="keyPress($event)">
                </div>
            </div>
            <div class="col-md-2">
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-4 eb-line-space">
                <div>
                    ZIP*
                    <input [(ngModel)]="zip" [ngClass]="{'eb-alert' : error && zip.length < 1}" type="text"
                        class="form-control eb-text-fld" aria-label="Text input with dropdown button" placeholder="ZIP"
                        (keyup)="keyPress($event)">
                </div>
            </div>
            <div class="col-md-4 eb-line-space">
                <div>
                    <div>
                        Current provider*
                    </div>
                    <!--
                    <select class="form-control eb-text-fld" id="cars" [(ngModel)]="provider"
                    [ngClass]="{'eb-alert' : error && provider.length < 1}" >
                        <option value="">Select a value</option>
                        <option value="i.company_id" *ngFor="let i of getProvider()"> {{i.company_name}} </option>
                    </select>
                    -->

                    <!--
                    <div ngbDropdown placement="bottom-right" class="d-inline-block"
                        style="width: 100%;">
                        <button style="border: 1px solid #ced4da; width: 100%;" class="btn eb-text-fld"
                            id="dropdownBasic1" ngbDropdownToggle  >
                            <div style="width: 97%; height: 20px; display: inline-flex; overflow: hidden;">
                                <span *ngIf="provider && provider.company_name" style="font-size: 14px;">
                                    {{provider.company_name}}
                                </span>
                            </div>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="max-height: 200px; overflow-y: scroll;">
                            <button style="font-size: 14px;" ngbDropdownItem (click)="selectedProviderDropDown(item)"
                                ngDefaultControl *ngFor="let item of getProvider()">{{item.company_name}}</button>
                        </div>
                    </div>
                    -->

                    <input id="typeahead-focus" type="text" class="form-control eb-text-fld" [(ngModel)]="provider"
                        [ngbTypeahead]="providerSearch" (focus)="focus$.next($any($event).target.value)"
                        (click)="click$.next($any($event).target.value)" #providerInstance="ngbTypeahead"
                        [resultFormatter]="formatter" [inputFormatter]="inputFormatter" />

                </div>
            </div>
            <div class="col-md-2">
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-4 eb-line-space">
                <div>
                    How long you have been in this address*
                    <!--
                    <select class="form-control eb-text-fld" id="duration" [(ngModel)]="duration"
                        [ngClass]="{'eb-alert' : error && duration.length < 1}">
                        <option value="">Select a value</option>
                        <option value="1 month">1 Month</option>
                        <option value="2month">2 Month</option>
                        <option value="6month">6 Month</option>
                        <option value="12month">12 Month</option>
                        <option value="more12month">More tnan 12 Month</option>
                    </select>
                    -->
                    <div ngbDropdown placement="bottom-left" class="d-inline-block" style="width: 100%;">
                        <button style="border: 1px solid #ced4da; width: 100%;" class="btn eb-text-fld"
                            id="dropdownBasic1" ngbDropdownToggle (keyup)="keyPress($event)">
                            <div style="width: 97%; height: 20px; display: inline-flex; overflow: hidden;">
                                <span *ngIf="duration && duration.value" style="font-size: 14px;">
                                    {{duration.value}}
                                </span>
                            </div>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                            style="max-height: 200px; overflow-y: scroll;">
                            <button style="font-size: 14px;" ngbDropdownItem (click)="selectedDurationDropDown(item)"
                                *ngFor="let item of initDuration()" ngDefaultControl>{{item.value}}</button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-4 eb-line-space">
                <div>
                    Current contract end date
                    <!--   -->
                    <form class="form-inline">
                        <div class="form-group" style="width: 100%;">
                            <div class="input-group" style="width: 100%;">
                                <input readonly class="form-control eb-text-fld" placeholder="mm-dd-yyyy" name="dp"
                                    [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" (keyup)="keyPress($event)">
                                <div class="input-group-append">

                                    <button [disabled]="false" class="btn btn-outline-secondary calendar eb-text-fld"
                                        style="background-color: #ffffff; border: 1px solid #ced4da;"
                                        (click)="d.toggle()" type="button">

                                        <span>
                                            <svg class="bi bi-calendar2-check" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                <path fill-rule="evenodd"
                                                    d="M14 2H2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z" />
                                                <path fill-rule="evenodd"
                                                    d="M3.5 0a.5.5 0 0 1 .5.5V1a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 .5-.5zm9 0a.5.5 0 0 1 .5.5V1a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 .5-.5z" />
                                                <path
                                                    d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!--  -->
                </div>
            </div>
            <div class="col-md-2">
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-10 eb-line-space">
                <div class="form-check form-check-inline">
                    <input [(ngModel)]="terms" class="form-check-input" type="checkbox" id="inlineCheckbox1"
                        [ngClass]="{'eb-alert' : error && terms === false}" value="option1" (keyup)="keyPress($event)">
                    <label style="font-size: 13px;" class="form-check-label" for="inlineCheckbox1">I agree
                        MyEnergyBuddy to pull my electricity usage</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 eb-line-space">
                <div style="display: flex; margin-top: 15px;">
                    <button style="margin-left: auto; margin-right: auto;" class="eb-up-btn eb-glb-btn"
                        (click)="onboard($event)" (keyup)="keyPress($event)">Complete Profile</button>
                </div>
            </div>
        </div>

    </div>
    <br>
</div>

<!-- ----- -->
<div style="padding-top: 30px;">
    <login-footer></login-footer>
</div>
<br>
<br>