<!--
<div>
    <input type="text" id="place" #searchEl [formControl]="searchControl" autocomplete="off" autocapitalize="off" spellcheck="off">
</div>
-->

<div>

    <div >
        <find-plan-shared></find-plan-shared>
    </div>
    <div class="container" (keyup)="keyPress($event)">

        <div class="row" style="margin-top: 40px;">
            <div class="col-md-2">
            </div>
            <div class="col-md-8 eb-line-space" style="text-align: center; color: #3e455c; font-size: 16px;">
                <div>
                    SMART METER TEXAS has sent an email with a confirmation link to
                </div>
                <div>
                    {{getEmail()}}
                </div>
                <div style="margin-top: 20px;">
                    <img style="max-height: 80%; max-width: 80%;" src="./assets/images/smartmeteremail.JPG" alt="">
                </div>
            </div>
            <div class="col-md-2">
            </div>
        </div>


        <div class="row">
            <div class="col-md-12 eb-line-space">
                <div style="display: flex; margin-top: 15px;">
                    <div style="margin-left: auto; margin-right: auto;">
                        <button class="eb-up-white-btn eb-glb-btn" (click)="skipClicked($event)" (keyup)="keyPress($event)">Skip</button>
                        &nbsp;&nbsp;
                        <button class="eb-up-btn eb-glb-btn" (click)="finishClick($event)" (keyup)="keyPress($event)">Finish</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 eb-line-space">
                <div *ngIf="showMeterMsg" style="display: flex; margin-top: 15px;">
                    <div style="margin-left: auto; margin-right: auto; border: 1px solid #d02f1b; color: #d02f1b; border-radius: 8px; padding: 10px 10px 10px 10px; font-size: 16px;">
                        <span>
                            Please click finish only after clicking the confirm button sent to your email
                        </span>
                        <br>
                        <span>
                            Incase you have already accepted the SMT email, please click <b style="cursor: pointer;" (click)="goToDefaultDashboard()"><u>PROCEED</u></b> to view to your dashboard
                        </span>
                        <br>
                        <span>
                            Please use the Skip button incase you want to complete this step later
                        </span>
                        <span *ngIf="false" style="padding-left: 20px;">
                            <svg class="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                                <path fill-rule="evenodd"
                                    d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <br>
</div>

<!-- ----- -->
<div style="padding-top: 30px;">
    <login-footer></login-footer>
</div>
<br>
<br>