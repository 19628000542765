<!-- <div class="eb-bg-clor-light eb-gbl-padding" style="padding-top: 50px; padding-bottom: 60px;">
    <div class="container">
        <div class="row" #feature>
            <div class="eb-h1" style="width: 100%;text-align: center; color: #00187b;">Why MyEnergyBuddy is different?</div>
            <div style="font-size: 18px; width: 100%;text-align: center; color: #3e455c; padding-top: 20px;">
                A simple and easy to use tool to pick the right plan for you
            </div>
        </div>
        <div class="row" style="text-align: center; padding-top: 50px;">
            <div class="col-md-4">
                <img class="eb-bottom-margin" src="./assets/images/whyeb1.png" width="50%"  alt="">
                <div class="eb-h2 eb-h2-blue eb-bottom-margin">Free to use</div>
                <div>Absolutely no cost to Texas consumers.
                    No signup fees or hidden charges.</div>
                <hr style="width: 50%; margin-top: 35px; margin-bottom: 35px; background-color: #004ba1;" class="hide-on-desktop">
            </div>
            <div class="col-md-4">
                <img class="eb-bottom-margin" src="./assets/images/whyeb2.png" width="50%"  alt="">
                <div class="eb-h2 eb-h2-blue eb-bottom-margin">Smart Algorithm</div>
                <div>MyEnergyBuddy looks at 100s of energy plans and recommends the cheapest based on your actual usage.</div>
                <hr style="width: 50%; margin-top: 35px; margin-bottom: 35px; background-color: #004ba1;" class="hide-on-desktop">
            </div>
            <div class="col-md-4">
                <img class="eb-bottom-margin" src="./assets/images/whyeb3.png" width="50%"  alt="">
                <div class="eb-h2 eb-h2-blue eb-bottom-margin">Transparency</div>
                <div>Texans can pick any of the best plans with direct enrollment links & peace of mind for a year long or more.</div>
            </div>
        </div>
    </div>
</div> -->
<div class="w-full" style="background-color:#eef3fa;">
  <div class="container py-5 py-md-7">

    <div class="row">
  <div class="h-wrapper col-md-12 text-center">
    <h3 class="text-navy">All your energy providers, in one place.</h3>
    <p class="subtext">Your choice, no preference to anyone.</p>
  </div>
</div>

    <div class="row">

  <div class="col-md-12">
    <div class="swiper-container logo-swiper swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-free-mode" [swiper]="{}">
      <div class="swiper-wrapper" id="swiper-wrapper-53dd8f9997a35a1a" aria-live="polite" style="transform: translate3d(-116px, 0px, 0px); transition-duration: 0ms;">

        <div class="swiper-slide mb-1" role="group" style="width: 96px; margin-right: 20px;" *ngFor="let image of images; let i = index" [attr.aria-label]="(i + 1) + '/ 21'">
          <img [src]="image" alt="All your energy providers, in one place." class="d-block w-100">
        </div>

      </div>
      <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-bullets-dynamic" style="width: 80px;">
      </div>
    <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>

  </div>
</div>

</div>
</div>
<div class="w-full bg-eb-img">
  <div
    class="container w-full d-flex flex-column align-items-center justify-content-between py-6 py-md-12 mx-auto">
    <div class="h-wrapper col-md-12 text-center">
      <h3 class="text-navy">Why Choose MyEnergyBuddy?</h3>
      <p class="subtext">A simple, easy-to-use platform to help you pick the best plan for you. </p>
    </div>
    <div class="row justify-content-center mt-0 mt-md-4">
      <div class="col-lg-4 col-md-6 my-1 why-eb-card">
        <div class="d-block border-radius-md bg-white text-center border p-5 h-100">
          <img class="d-block img-120 m-auto"
            src="/assets/images/PNGs_Homepage_Why_Free@2x.png"
            alt="Free to Use">
          <p class="text-navy line-height-160 font-weight-bolder my-1 heading">Free to Use</p>
          <p class="line-height-160 mb-0 subtext">Absolutely no cost to Texas customers. No signup fees, no hidden charges. </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 my-1 why-eb-card">
        <div class="d-block border-radius-md bg-white text-center border p-5 h-100">
          <img class="d-block img-120 m-auto"
            src="/assets/images/PNGs_Homepage_Why_Plan@2x.png"
            alt="Your Usage, Your Plan">
          <p class="text-navy line-height-160 font-weight-bolder my-1 heading">Your Usage, Your Plan</p>
          <p class="line-height-160 mb-0 subtext">MyEnergyBuddy’s smart algorithm looks at 1000s of energy plans and recommends
            the cheapest plans based on your actual usage.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 my-1 why-eb-card">
        <div class="d-block border-radius-md bg-white text-center border p-5 h-100">
          <img class="d-block img-120 m-auto"
            src="/assets/images/PNGs_Homepage_Why_Unbiased@2x.png"
            alt="100% Unbiased">
          <p class="text-navy line-height-160 font-weight-bolder my-1 heading">100% Unbiased</p>
          <p class="line-height-160 mb-0 subtext">MyEnergyBuddy is transparent and we don’t try to get you a plan that pays us a
            higher commission. </p>
        </div>

      </div>

    </div>
  </div>
</div>
<div class="w-full">
  <div class="container pt-5 pt-md-0">
    <div class="h-wrapper col-md-12 text-center">
      <h3 class="text-navy">How Much Can You Save on Your Electricity Bill?</h3>
      <p class="subtext">Analysis of a Texas Home</p>
      <a class="btn btn-primary me-2  py-4 px-5  get-plan" href="#" title="Get Real Savings" target="" (click)="onGetRealSavingsClick()">
        Get Real Savings</a>
    </div>
    <div class="w-full">
      <img loading="lazy" class="img-fluid" src="/assets/images/IMG_Homepage_Graph@2x-scaled.jpg" alt="" width="2560" height="1410" srcset="/assets/images/IMG_Homepage_Graph@2x-scaled.jpg 2560w, /assets/images/IMG_Homepage_Graph@2x-450x248.jpg 450w, /assets/images/IMG_Homepage_Graph@2x-992x547.jpg 992w, /assets/images/IMG_Homepage_Graph@2x-768x423.jpg 768w, /assets/images/IMG_Homepage_Graph@2x-1536x846.jpg 1536w, /assets/images/IMG_Homepage_Graph@2x-2048x1128.jpg 2048w" sizes="(max-width: 2560px) 100vw, 2560px" />
    </div>
  </div>
</div>
