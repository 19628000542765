<!-- <div class="">
    <div class="hide-on-mobile">
        <div class="eb-bg-clor eb-gbl-padding">
            <div class="container">
                <div class="row eb-bg-clor">
                    <div class="col" style="text-align: center; margin-bottom: -30px;">
                        <img width="25%" style="position: relative; top: -30px;" src="./assets/images/footer_house.png"/>
                        <span class="eb-h2" style="color: white; padding-left: 20px; padding-right: 20px; position: relative; top: -8px;">Want to reduce your energy bill? </span>
                        <span (click)="scrollTop()" style="cursor: pointer;background-color: white; color: #17b50d; border-radius: 20px; padding-top: 10px; padding-bottom: 10px; position: relative; top: -12px;">&nbsp;&nbsp;<b>Get Started</b>&nbsp;&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding-top: 20px;padding-bottom: 20px;">
            <span style="padding-left: 20px;">© 2022 MyEnergyBuddy</span>
            <span style="float: right; padding-right: 20px;"><a (click)="showTnc()" style="cursor: pointer;">Terms & Conditions</a> | <a (click)="showPolicy()" style="cursor: pointer;">Privacy Policy</a></span>
        </div>
    </div>

    <div class="hide-on-desktop">
        <div class="eb-bg-clor eb-gbl-padding">
            <div class="container">
                <div class="row eb-bg-clor">
                    <div class="col" style="text-align: center; margin-bottom: -15px;">
                        <div>
                        <img class="eb-top" width="35%" style="" src="./assets/images/footer_house.png"/>
                        </div>
                        <div class="eb-h2 eb-top" style="color: white; padding-left: 20px; padding-right: 20px; margin-bottom: 8px;">Want to reduce your energy bill? </div>
                        <span (click)="scrollTop()" class="eb-top eb-glb-btn" style="cursor: pointer;background-color: white; color: #17b50d; border-radius: 30px; width: 50%;"><b>Get Started</b></span>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding-top: 10px;padding-bottom: 10px; text-align: center;">
            <span style="font-size: 14px;"><a (click)="showTnc()" style="cursor: pointer;">Terms & Conditions</a> | <a (click)="showPolicy()" style="cursor: pointer;">Privacy Policy</a></span>
            <br>
            <span style="padding-left: 20px;font-size: 10px;">© 2022 MyEnergyBuddy</span>
        </div>
    </div>

</div> -->

<!-- Pre Footer -->
<div class="container mb-7 mb-md-12">
  <div class="row">
    <div class="col-md-12">
      <div class="pre-footer h-wrapper">
        <img
          class="pre-footer__img"
          src="/assets/images/Homepage_Mascot_CTA.png"
          alt=""
        />

        <h3 class="pre-footer__text">Want to reduce your energy bill?</h3>

        <div class="pre-footer__button">
          <a href="#" class="btn btn-primary get-plan" title="Get Started" target="" (click)="onGetStartedClick()"
            >Get Started</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Main Footer -->
<div class="d-block w-100 bg-footer footer py-5">
  <div class="container">
    <div class="footer__wrapper">
      <ul>
        <li>
          <img
            class="img-120"
            src="/assets/images/energy-buddy-logo.png"
          />
        </li>

        <li class="eb-my-3">
          MyEnergyBuddy is committed to helping Texans save money on their
          electricity bills. Our unbiased algorithm takes the information you
          provide and scours the internet until we find the best prices for your
          unique usage. Contact us today to learn more.
        </li>

        <li>
          <div class="d-flex">
            <svg
              class="me-2"
              id="fi:mail"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="Vector"
                d="M2,0H18a2.006,2.006,0,0,1,2,2V14a2.006,2.006,0,0,1-2,2H2a2.006,2.006,0,0,1-2-2V2A2.006,2.006,0,0,1,2,0Z"
                transform="translate(2 4)"
                fill="none"
                stroke="#2abc26"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
              <path
                id="Vector-2"
                data-name="Vector"
                d="M20,0,10,7,0,0"
                transform="translate(2 6)"
                fill="none"
                stroke="#2abc26"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
            </svg>
            <a href="mailto:hello@myenergybuddy.com">hello@myenergybuddy.com</a>
          </div>
          <div class="footer-socials">
            <a href="#"
              ><img
                src="/assets/images/Facebook.svg"
                alt="patent"
                width="auto"
                class="img-30" /></a
            ><a href="#"
              ><img
                src="/assets/images/twitter.svg"
                alt="patent"
                width="auto"
                class="img-30" /></a
            ><a href="#"
              ><img
                src="/assets/images/instagram.svg"
                alt="patent"
                width="auto"
                class="img-30" /></a
            ><a href="#"
              ><img
                src="/assets/images/linkedin.svg"
                alt="patent"
                width="auto"
                class="img-30"
            /></a>
          </div>
        </li>

        <li><strong class="d-block pt-2">BROKER ID: BR200189</strong></li>
      </ul>

      <ul class="px-5 px-md-0 pt-3 pt-md-0">
        <li><strong class="txt-green d-block pb-1">Company</strong></li>
        <li><a title="Our Values" target="" href="#">Our Values</a></li>
        <li>
          <a title="Terms &amp; Conditions" target="" href="#"
            >Terms &amp; Conditions</a
          >
        </li>
        <li><a title="Privacy Policy" target="" href="#">Privacy Policy</a></li>
      </ul>

      <ul class="px-5 px-md-0 pt-3 pt-md-0">
        <li><strong class="txt-green d-block pb-1">About us</strong></li>
        <li><a title="Our Values" target="" href="#">Our Values</a></li>
        <li>
          <a title="Terms &amp; Conditions" target="" href="#"
            >Terms &amp; Conditions</a
          >
        </li>
        <li><a title="Privacy Policy" target="" href="#">Privacy Policy</a></li>
      </ul>
    </div>

    <div class="row">
      <div
        class="col-md-12 text-center text-white mt-3 mt-md-5 pt-3 pt-md-5 border-top"
      >
        <small>© 2021-2023 Byigg Inc.</small>
      </div>
    </div>
  </div>
</div>
