import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgbAccordionModule  } from '@ng-bootstrap/ng-bootstrap';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
  }
};

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FindPlanComponent } from './comps/findPlanComp/find-plan.component';
import { AppHeaderComponent } from './comps/header/app-header.component';
import { HomeComponent } from './home.components';
import { WhyEnergyBuddyComponent } from './comps/landing/whyeb/whyEnergyBuddy.component';
import { WhyNeedEBComponent } from './comps/landing/ebneed/why-need.component';
import { UserSayComponent } from './comps/landing/usersay/user-say.component';
import { AppFooterComponent } from './comps/landing/footer/footer.component';
import { ResultWithoutSignUp } from './comps/landing/resultsWithoutSignUp/resultwosu.component';
import { PopupMsgComponent } from './comps/landing/popupMsg/popupmsg.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ResultService } from './service/result.service';
import { HttpClientModule } from '@angular/common/http';
import { HelpMsgComponent } from './comps/landing/helpMsg/helpmsg.component';
import { LoginModule } from './comps/login/login.module';
import { AppService } from './service/app.service';
import { Constants } from './service/constants';
import { DummyData } from './service/dummydata.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { ActivationComponent } from './comps/login/activation/activation.component';
import { SharedModule } from './comps/shared/shared.module';
import { HowItWorksComponent } from './comps/how-it-works/how-it-works.component';
import { HearFromOthersComponent } from './comps/hear-from-others/hear-from-others.component';

@NgModule({
  declarations: [
    AppComponent,
    FindPlanComponent,
    AppHeaderComponent,
    HomeComponent,
    WhyEnergyBuddyComponent,
    WhyNeedEBComponent,
    UserSayComponent,
    AppFooterComponent,
    ResultWithoutSignUp,
    PopupMsgComponent,
    HelpMsgComponent,
    ActivationComponent,
    HowItWorksComponent,
    HearFromOthersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    LoginModule,
    BrowserAnimationsModule,
    MatSliderModule,
    SharedModule,
    SwiperModule,
    // NgbAccordionModule
  ],
  providers: [
    ResultService,
    AppService,
    DummyData,
    Constants,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
